import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'

class KBTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    return (
      <Layout>
        <Helmet>
          <title>{post.frontmatter.title}</title>
        </Helmet>
        <h3>{post.frontmatter.title}</h3>
        <br />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <br />
        <br />
        <Link to="/kb">&larr; Knowledge Base</Link>
      </Layout>
    )
  }
}

export default KBTemplate

export const pageQuery = graphql`
  query KBBySlug($slug: String!) {
    markdownRemark(frontmatter: { permalink: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`
